import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "faq"
    }}>{`FAQ`}</h1>
    <h2 {...{
      "id": "类型推断"
    }}>{`类型推断`}</h2>
    <p>{`在编写 `}<inlineCode parentName="p">{`Effect/SSREffect`}</inlineCode>{` 逻辑的时候，如果这个 `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` 包含触发自身的逻辑或者多个 `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` 互相依赖, 则会出现类型推断失败的情况:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-type-infer-3lwum"
      }}>{`Example in CodeSandbox`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-type-infer-3lwum?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-type-infer" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { EffectModule, Module, ImmerReducer, Effect } from "@sigi/core";
import { Draft } from "immer";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface CountState {
  count: number;
}

@Module("Count")
export class CountModule extends EffectModule<CountState> {
  defaultState = {
    count: 0,
  };

  @ImmerReducer()
  set(state: Draft<CountState>, payload: number) {
    state.count = state.count + payload;
  }

  @Effect()
  addLeastFive(payload$: Observable<number>) {
    return payload$.pipe(
      map((payload) => {
        if (payload < 5) {
          return this.getActions().addLeastFive(payload + 1);
        }
        return this.getActions().set(payload);
      })
    );
  }
}
`}</code></pre>
    <p>{`此时需要显示标注 `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` 的返回值:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- import { EffectModule, Module, ImmerReducer, Effect } from '@sigi/core'
+ import { EffectModule, Module, ImmerReducer, Effect, Action } from '@sigi/core'

- addLeastFive(payload$: Observable<number>) {
+ addLeastFive(payload$: Observable<number>): Observable<Action> {
`}</code></pre>
    <h2 {...{
      "id": "effect-结束回调"
    }}>{`Effect 结束回调`}</h2>
    <p>{`有几种常见的业务场景需要在 `}<strong parentName="p">{`Effect`}</strong>{` 结束后执行 `}<strong parentName="p"><em parentName="strong">{`回调`}</em></strong>{`:`}</p>
    <h3 {...{
      "id": "成功失败后展示消息"
    }}>{`成功/失败后展示消息`}</h3>
    <p>{`取决于展示消息的 API，我们可以用`}<strong parentName="p">{`数据驱动`}</strong>{`/`}<strong parentName="p">{`副作用`}</strong>{`两种方式来实现这个需求:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-effects-callback-f403k"
      }}>{`CodeSandbox effects callback side effect`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-effects-callback-f403k?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-effects-callback-side-effect" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Module, EffectModule, Reducer, Effect, Action } from "@sigi/core";
import { Observable, of } from "rxjs";
import {
  exhaustMap,
  takeUntil,
  map,
  tap,
  startWith,
  catchError,
} from "rxjs/operators";
import { message } from "antd";

import { HttpClient } from "./http.service";

interface AppState {
  list: string[] | null | Error;
}

@Module("App")
export class AppModule extends EffectModule<AppState> {
  defaultState: AppState = {
    list: [],
  };

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  @Reducer()
  cancel(state: AppState) {
    return { ...state, ...this.defaultState };
  }

  @Reducer()
  setList(state: AppState, list: AppState["list"]) {
    return { ...state, list };
  }

  @Effect()
  fetchList(payload$: Observable<void>): Observable<Action> {
    return payload$.pipe(
      exhaustMap(() => {
        return this.httpClient.get(\`/resources\`).pipe(
          tap(
            () => {
              message.success("Got response");
            },
            (e) => {
              message.error(e.message);
            }
          ),
          map((response) => this.getActions().setList(response)),
          catchError((e) => of(this.getActions().setList(e))),
          startWith(this.getActions().setList(null)),
          takeUntil(this.getAction$().cancel)
        );
      })
    );
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-faq-effects-pure-state-msbz4"
      }}>{`CodeSandbox effects callback state driven`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-faq-effects-pure-state-msbz4?fontsize=14&hidenavigation=1&module=%2Fsrc%2Fapp.module.ts&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-faq-effects-callback-side-effect" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import "reflect-metadata";
import "antd/dist/antd.css";
import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { useModule } from "@sigi/react";
import { initDevtool } from "@sigi/devtool";
import { Modal } from "antd";

import { AppModule } from "./app.module";

function App() {
  const [{ list }, dispatcher] = useModule(AppModule);
  const [modalVisible, setModalVisible] = useState(true);
  const onFetchList = useCallback(() => {
    setModalVisible(true);
    dispatcher.fetchList();
  }, [dispatcher, setModalVisible]);
  const onClose = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const loading = !list ? <div>loading</div> : null;

  const title =
    list instanceof Error ? (
      <>
        <Modal
          title="fail"
          visible={modalVisible}
          onOk={onClose}
          onCancel={onClose}
        >
          <p>{list.message}</p>
        </Modal>
        <h1>{list.message}</h1>
      </>
    ) : (
      <h1>Hello CodeSandbox</h1>
    );

  const listNodes = Array.isArray(list)
    ? list.map((value) => <li key={value}>{value}</li>)
    : null;
  return (
    <div>
      {title}
      <button onClick={onFetchList}>fetchList</button>
      <button onClick={dispatcher.cancel}>cancel</button>
      {loading}
      <ul>{listNodes}</ul>
    </div>
  );
}

const rootElement = document.getElementById("app");
render(<App />, rootElement);

initDevtool();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      